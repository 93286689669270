import ReactDOMClient from 'react-dom/client';
import { Flowbite } from 'flowbite-react';
import App from './App';
import './index.css';
import { CustomFlowbiteTheme } from 'flowbite-react';
const customTheme: CustomFlowbiteTheme = {
  sidebar: {
    inner:
      'h-full overflow-y-auto overflow-x-hidden rounded-[22px] bg-white px-3 py-4 dark:bg-primary-800 transition-all duration-300',
    item: {
      base: 'flex items-center justify-center rounded-[22px] p-2 text-base text-lightTextColor dark:text-headingColor font-weight-400 !bg-transparent hover:!bg-transparent',
      active: '!text-lightTextColor dark:text-primary-700',
      content: {
        base: 'flex-1 whitespace-nowrap px-3',
      },
      icon: {
        base: 'h-6 w-6 flex-shrink-0 text-lightTextColor transition duration-75 group-hover/item:text-lightTextColor dark:text-headingColor dark:group-hover/item:!text-primary-700 transition-colors duration-300',
        active: 'text-lightTextColor dark:text-primary-700',
      },
    },
  },
  listGroup: {
    item: {
      base: 'w-full !rounded-[22px] hover:!bg-primary-50 dark:hover:!bg-darkSidebarItem overflow-hidden duration-300 transition-colors',
    },
  },
};
try {
  const container = document.getElementById('root');
  if (!container) throw new Error('No root element was found in the document.');
  ReactDOMClient.createRoot(container).render(
    // <React.StrictMode>
    <Flowbite theme={{ theme: customTheme }}>
      <App />
    </Flowbite>,
    // </React.StrictMode>
  );
} catch (error) {
  console.error(error);
}
