import { createSlice } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

export const initialProfile = {
  // Personal
  name: '',
  bio: '',
  id: '',
  email: '',
  profileImage: '',
  profileId: '',
  userId: '',
  isOrganization: false,
  orgId: '',
  //Social
  industry: '',
  website: '',
  twitter: '',
  discord: '',
  telegram: '',
  kaggle: '',
  github: '',
  linkedin: '',
  // Progress
  challengeCount: 0,
  solvedChallenges: [],
  totalSubmission: 0,
  totalRewards: 0,
  rewardCurrency: 'USD',
  submissions: [],
  challenges: [],
  organizations: [],
  admins: [],
  //Invoice info
  country: '',
  payment_wallet: '',
  lastname: '',
  firstname: '',
  newsLastSeen: NaN,
  gems: 0,
};

export const profileInitialState = {
  currentProfile: initialProfile,
  defaultProfile: initialProfile,
};

let initialState = profileInitialState;
// Localstorage persistense - Not tested well.
const presistedState = localStorage.getItem('persistedstate');
if (presistedState) {
  initialState = JSON.parse(presistedState)?.profile ?? profileInitialState;
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    profileReset: () => profileInitialState,
    updateProfileDetails: (state, { payload }) => {
      state.currentProfile = { ...state.currentProfile, ...payload };
      state.defaultProfile = { ...state.defaultProfile, ...payload };
    },
    updateCurrentProfile: (state, { payload }) => {
      state.currentProfile = { ...state.currentProfile, ...payload };
    },
    updateDefaultProfile: (state, { payload }) => {
      state.defaultProfile = { ...state.defaultProfile, ...payload };
    },
    addProposalToProfile: (state, { payload }) => {
      state.defaultProfile.submissions.push(payload.proposalId);
      if (state.currentProfile.profileId === payload.profileId) {
        state.currentProfile.submissions.push(payload.proposalId);
      }
    },
    removeProposalFromProfile: (state, { payload }) => {
      state.defaultProfile.submissions = state.defaultProfile.submissions.filter(
        (proposalId: string) => proposalId !== payload.proposalId,
      );
      if (state.currentProfile.profileId === payload.profileId) {
        state.currentProfile.submissions = state.currentProfile.submissions.filter(
          (proposalId: string) => proposalId !== payload.proposalId,
        );
      }
    },
    addAdminToCurrentProfile: (state, { payload }) => {
      if (state.currentProfile.profileId === payload.profileId) {
        state.currentProfile.admins.push(payload.adminProfileId);
      }
    },
    removeAdminFromCurrentProfile: (state, { payload }) => {
      state.currentProfile.admins = state.currentProfile.admins.filter(
        (id) => id !== payload.userProfileId,
      );
      if (state.defaultProfile.profileId === payload.userProfileId) {
        // This is happeneing because the current user exiting org.
        state.defaultProfile.organizations = state.defaultProfile.organizations.filter(
          (id) => id !== state.currentProfile.profileId,
        );
        state.currentProfile = { ...state.defaultProfile };
      }
    },
    addOrgToProfile: (state, { payload }) => {
      state?.currentProfile?.organizations?.push(payload);
      state?.defaultProfile?.organizations?.push(payload);
    },
    addOrgsToProfile: (state, { payload }) => {
      if (state?.currentProfile) {
        state.currentProfile.organizations = uniq([
          ...(state.currentProfile.organizations || []),
          ...payload,
        ]);
      }
      if (state?.defaultProfile) {
        state.defaultProfile.organizations = uniq([
          ...(state.defaultProfile.organizations || []),
          ...payload,
        ]);
      }
    },
    addChallengesToProfile: (state, { payload }) => {
      if (state.currentProfile.profileId === payload.orgProfileId) {
        state.currentProfile.challenges = payload.challenges;
      }
    },
  },
});

export default profileSlice.reducer;
export const {
  addProposalToProfile,
  profileReset,
  updateProfileDetails,
  updateCurrentProfile,
  updateDefaultProfile,
  removeProposalFromProfile,
  addAdminToCurrentProfile,
  removeAdminFromCurrentProfile,
  addOrgToProfile,
  addOrgsToProfile,
  addChallengesToProfile,
} = profileSlice.actions;
