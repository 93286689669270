import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SlWallet } from 'react-icons/sl';
import { Button } from 'flowbite-react';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount } from 'wagmi';
import { isMobile } from 'react-device-detect';

import {
  fetchuserProfileId,
  fetchUserProfileData,
  formatAddress,
  isUserProfileRegistered,
  // createUserInDB,
  signRandomMessage,
  verifySignatureAndReturnAddress,

  // isWalletInvited,
} from '../Utils/Helpers';
import { AppDispatch, RootState } from '../@types';
import { ethers } from 'ethers';
import {
  updateGuestStateAction,
  updateProfileDetailsAction,
  fetchUserOrgsAction,
} from '../Store/Actions';
import {
  ADDRESS_NOT_VALID,
  CHALLENGE_PATH,
  GUEST_PROFILE_CREATE_PATH,
  HERO_PATH,
  LIST_PATH,
  SHARED_PATH,
  // SIGNUP_PATH,
  USER_CHALLENGE_LIST_PATH,
  USER_PROFILE_DETAILS_PATH,
  USER_SUBMISSIONS_LIST_PATH,
  WALLET_BUTTON_WARNING_TEXT,
  WALLET_CONNECT_WARNING_TEXT,
} from '../Utils/Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { updateMember } from '../Store/Slices/MemberSlice';
import {
  // updateGuestState,
  updateInitialLoading,
  updateOrgSelected,
} from '../Store/Slices/UtilsSlice';
import { toggleSignUpModalAction, toggleSignUpModalForInfoAction } from '../Store/Slices/MiscSlice';
import { BiWallet } from 'react-icons/bi';
import { resetRootState } from '../Store';
// import { profileReset } from '../Store/Slices/ProfileSlice';
import CustomToast from '../Components/CustomToast';
import CommonWarningModal from './Modals/CommonWarningModal';
import { useDisconnect } from 'wagmi';
import { useSignInTracking } from '../Utils/Hooks/useSignInTracking';
import { setCookie } from '../Utils/Helpers/CommonHelpers';

interface ConnectWalletProps {
  buttonText: string;
  responsive?: boolean;
  setLoginError?: (value: boolean) => void;
  // setShowSignUpModal?: (value: boolean) => void;
  onClick?: () => void;
  isCollapsed?: boolean;
}

const ConnectWallet = ({
  buttonText,
  setLoginError,
  // setShowSignUpModal,
  onClick,
  isCollapsed,
}: ConnectWalletProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);

  const defaultProfile = useSelector((state: RootState) => state.profile.defaultProfile);
  const isOrgSelected = useSelector((state: RootState) => state.utils.isOrgSelected);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const { open } = useWeb3Modal();
  const dispatch: AppDispatch = useDispatch();
  const isGuest = useSelector((state: RootState) => state.utils.isGuest);
  const { trackSignIn } = useSignInTracking();
  const { disconnect } = useDisconnect();
  const {
    isConnected,
    address: userAddress,
    status,
  } = useAccount({
    async onConnect({ address }) {
      // if (setShowSignUpModal) {
      //   setShowSignUpModal(false);
      // }
      dispatch(toggleSignUpModalAction(false));
      const userProfileId = await fetchuserProfileId(address);
      await trackSignIn({
        provider: 'wallet',
        status: 'success',
        email: '',
        userId: userProfileId || address || '',
      });
    },
    async onDisconnect() {
      dispatch(resetRootState());
      await trackSignIn({
        provider: 'wallet',
        status: 'failed',
        email: '',
        userId: '',
      });
    },
  });

  const connectWallet = async () => {
    console.log('ConnectWallet: Starting wallet connection');
    dispatch(resetRootState());
    if (isMobile) {
      setShowWarningModal(true);
      await trackSignIn({
        provider: 'wallet',
        status: 'failed',
        email: '',
        userId: '',
      });
      return;
    }

    try {
      if (onClick) {
        await onClick();
      }
      await open();
    } catch (error) {
      console.error('ConnectWallet: Error:', error);
      await trackSignIn({
        provider: 'wallet',
        status: 'failed',
        email: '',
        userId: '',
      });
    }
  };
  const handleUndeplyedUser = async () => {
    const userProfileId = await fetchuserProfileId(userAddress);
    // const isRegistered = await isUserProfileRegistered(userProfileId);
    // if (isRegistered) {
    await trackSignIn({
      provider: 'wallet',
      status: 'success',
      email: '',
      userId: userProfileId,
    });
    // return;
    // }
    dispatch(updateInitialLoading(true));
    // const undeployedUser = await verifySignatureAndReturnUser(userAddress);
    // if (undeployedUser) {
    const userProfileData = await fetchUserProfileData(userProfileId);
    // @ts-expect-error mixed type
    if (!userProfileData.firstname) {
      dispatch(toggleSignUpModalForInfoAction(true));
    }
    dispatch(updateProfileDetailsAction({ ...userProfileData, id: userProfileData?.profileId }));
    // }
    dispatch(updateInitialLoading(false));
    dispatch(updateGuestStateAction(false));
    if (location.pathname.includes(CHALLENGE_PATH + LIST_PATH)) {
      /**If users login from challenge list path */
      navigate(USER_CHALLENGE_LIST_PATH);
      return;
    } else {
      navigate(location.pathname);
      return;
    }
  };
  const fetchUserProfile = async () => {
    // dispatch(profileReset());
    dispatch(updateInitialLoading(true));
    // const userInvited = await isWalletInvited(userAddress, process.env.REACT_APP_REGISTRY);
    const userProfileId = await fetchuserProfileId(userAddress);
    if (!userProfileId) {
      dispatch(updateInitialLoading(false));
      //   dispatch(updateGuestState(true));
      //   if (!isGuest) {
      //     // navigate(SIGNUP_PATH);
      //   }
      return;
    }

    const isValidAddress = ethers.isAddress(userProfileId);
    if (!isValidAddress) {
      return;
    }
    if (isValidAddress) {
      const isRegistered = await isUserProfileRegistered(userProfileId);
      if (!isRegistered) {
        dispatch(updateInitialLoading(false));
        return;
      }
      if (isRegistered) {
        const userProfileData = await fetchUserProfileData(userProfileId);
        //@ts-expect-error mixed type
        if (!userProfileData.firstname) {
          dispatch(toggleSignUpModalForInfoAction(true));
        }
        dispatch(fetchUserOrgsAction(userProfileId));
        dispatch(updateGuestStateAction(false));
        dispatch(updateMember(userProfileData));
        dispatch(updateInitialLoading(false));
        if (!isOrgSelected) {
          /**This block runs when org is not selected and page refreshes or user logins and is registered*/
          dispatch(
            updateProfileDetailsAction({ ...userProfileData, id: userProfileData?.profileId }),
          );
          if (location.pathname.includes(CHALLENGE_PATH + LIST_PATH)) {
            /**If users login from challenge list path */
            navigate(USER_CHALLENGE_LIST_PATH);
            return;
          } else {
            navigate(location.pathname);
            return;
          }
          navigate(USER_PROFILE_DETAILS_PATH + '/' + userProfileId);
          // navigate(USER_PROFILE_DETAILS_PATH + "/" + userProfileId);
        }
        if (userProfileData.profileId !== defaultProfile.profileId) {
          // Account switched.
          if (isOrgSelected) {
            // User in the org profile.
            dispatch(updateOrgSelected(false));
          }
          dispatch(
            updateProfileDetailsAction({ ...userProfileData, id: userProfileData?.profileId }),
          );
          navigate(USER_PROFILE_DETAILS_PATH + '/' + userProfileId);
        }
        if (location.pathname.includes(SHARED_PATH)) {
          // User have account and visiting shared path
          navigate(location.pathname.replace(SHARED_PATH, ''));
        }
        if (
          location.pathname === ADDRESS_NOT_VALID ||
          location.pathname === GUEST_PROFILE_CREATE_PATH
        ) {
          // User have account and seeing the address not valid screen or user create form.
          navigate(USER_SUBMISSIONS_LIST_PATH);
        }

        return;
      }
      // If user is not registered
    }
    // else if (userInvited && location.pathname === ADDRESS_NOT_VALID) {
    //   /**  These two happens when the wallet switched on the @ADDRESS_NOT_VALID and @GUEST_PROFILE_CREATE_PATH */
    //   navigate(SIGNUP_PATH);
    // } else if (!userInvited && location.pathname === GUEST_PROFILE_CREATE_PATH) {
    //   navigate(ADDRESS_NOT_VALID);
    // }
    dispatch(updateGuestStateAction(true));
    dispatch(updateInitialLoading(false));
  };

  useEffect(() => {
    // console.log('effect running', userAddress);
    // dispatch(resetRootState());
    if (!isConnected) {
      // localStorage.removeItem('persistConnect');
      localStorage.removeItem('currAddress');
      localStorage.setItem('hasDisconnected', 'true');
    }
    if (
      isConnected &&
      userAddress &&
      userAddress != localStorage.getItem('currAddress') &&
      status === 'connected'
    ) {
      if (localStorage.getItem('hasDisconnected')) {
        localStorage.removeItem('hasDisconnected');
      } else {
        const message = `action: Login \ntimestamp: ${new Date().toISOString()}`;
        dispatch(resetRootState());
        localStorage.setItem('currAddress', userAddress);
        signRandomMessage(message)
          .then((signatureResponse) => {
            // console.log('isConnected fetch under hook');
            if (signatureResponse) {
              verifySignatureAndReturnAddress(message, signatureResponse)
                .then((payload) => {
                  const walletAddress = payload.walletAddress;
                  const jwtToken = payload.jwt_token;
                  setCookie('socialLogin', jwtToken);
                  // console.log('walletAddress', walletAddress);
                  if (walletAddress === userAddress) {
                    fetchUserProfile();
                    handleUndeplyedUser();
                  } else {
                    disconnect();
                    setLoginError?.(true);
                    // setShowSignUpModal?.(true);
                    dispatch(toggleSignUpModalAction(true));
                    dispatch(updateInitialLoading(false));
                    localStorage.removeItem('currAddress');
                  }
                })
                .catch(() => {
                  disconnect();
                  setLoginError?.(true);
                  // setShowSignUpModal?.(true);
                  dispatch(toggleSignUpModalAction(true));
                  dispatch(updateInitialLoading(false));
                  localStorage.removeItem('currAddress');
                });

              // localStorage.setItem('persistConnect', 'true');
            } else {
              disconnect();
              setLoginError?.(true);
              // setShowSignUpModal?.(true);
              dispatch(toggleSignUpModalAction(true));
              dispatch(updateInitialLoading(false));
              localStorage.removeItem('currAddress');
            }
          })
          .catch(() => {
            disconnect();
            setLoginError?.(true);
            // setShowSignUpModal?.(true);
            dispatch(toggleSignUpModalAction(true));
            dispatch(updateInitialLoading(false));
            localStorage.removeItem('currAddress');
          });
      }

      /**If user is logged in and the wallet gets disconnected the toast will show and logging out the user */
    } else if (status === 'disconnected' && !isGuest) {
      dispatch(resetRootState());
      if (location.pathname !== HERO_PATH) {
        setShowToast(true);
        open();
      }
    }
  }, [isConnected, userAddress]);

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  }, [showToast]);

  // useEffect(() => {
  //setting as false when when wallet address changes only user will be logged in for sure
  // dispatch(updateOrgSelected(false));
  // }, [userAddress]);

  return (
    <>
      <CommonWarningModal
        buttonText={WALLET_BUTTON_WARNING_TEXT}
        show={showWarningModal}
        warningText={WALLET_CONNECT_WARNING_TEXT}
        onButtonClick={() => {
          setShowWarningModal(false);
        }}
      />
      {!isConnected ? (
        <Button
          className='self-center text-md bg-tabColor !bg-primary-700 !text-lightTextColor mx-auto'
          onClick={connectWallet}
          size='md'
        >
          <SlWallet className='h-[30px] w-[30px]' />
          <span className={`ml-2`}>{buttonText}</span>
        </Button>
      ) : (
        <div
          className={`self-center flex gap-2 text-lightTextColor dark:text-darkTextColor rounded-lg border text-base font-medium border-primary-700 px-3 py-2 shadow-lg shadow-primary-200 dark:shadow-primary-600 ${
            isCollapsed ? 'hidden' : ''
          }`}
        >
          <BiWallet className='text-primary-400 self-center text-xl' />
          <span>{formatAddress(userAddress)}</span>
        </div>
      )}
      {showToast && <CustomToast text={'Wallet not connected'} setShowToast={setShowToast} />}
    </>
  );
};

export default ConnectWallet;
