// import { useEffect, useState } from 'react';
import { setCookie } from '../../Utils/Helpers/CommonHelpers';
import CommonButton from '../CommonButton';
// import CommonModal from '../CommonModal';

interface CookieModalProps {
  showConcentPopup: boolean;
  setShowConcentPopup: (show: boolean) => void;
  allCookies?: { name: string; description: string }[]; // Default array of cookies
  essentialCookies?: string[];
}
const CookieModal = ({
  showConcentPopup,
  setShowConcentPopup,
  allCookies = [
    {
      name: 'Analytics',
      description: 'Used to collect information about how visitors use our website.',
    },
    {
      name: 'Marketing',
      description: 'Used to deliver advertisements that are relevant to you and your interests.',
    },
    {
      name: 'Functional',
      description: 'Used to remember your preferences and enhance your experience.',
    },
    {
      name: 'Necessary',
      description: 'Essential for the website to function properly and cannot be disabled.',
    },
  ],
  // essentialCookies = ['Functional', 'Necessary'],
}: CookieModalProps) => {
  // const [selectedCookies, setSelectedCookies] = useState<string[]>([...essentialCookies]);
  // const [showCustomizePopup, setShowCustomizePopup] = useState(false);
  // useEffect(() => {
  //   if (showCustomizePopup) {
  //     const preferences = getCookie('preferences') || [...essentialCookies];
  //     setSelectedCookies(preferences);
  //   }
  // }, [showCustomizePopup]);

  // function toggleCookieSelection(cookieName: string) {
  //   setSelectedCookies((prevSelected) =>
  //     prevSelected.includes(cookieName)
  //       ? prevSelected.filter((name) => name !== cookieName)
  //       : [...prevSelected, cookieName],
  //   );
  // }

  const enableCookies = (cookieArray: string[] | null) => {
    // const enabledCookies = cookieArray.map((cookie) => cookie.name);
    setCookie('preferences', cookieArray);
    if (cookieArray && cookieArray.length !== 0) {
      setShowConcentPopup(false);
    } else {
      setShowConcentPopup(true);
    }
  };

  return (
    <div className='fixed bottom-[65px] right-[20px] animate-unHide z-50'>
      {showConcentPopup && (
        <div className='bg-white dark:bg-primary-800 p-4 rounded-lg shadow-lg max-w-[370px] border border-primary-700 border-[1px]'>
          <h2 className='text-sm font-bold dark:text-headingColor'>Welcome to Desights!</h2>
          <p className='text-xs text-gray-600 dark:text-headingColor'>
            We use some necessary cookies to remember your preferences and enhance your experience.
            These are essential for website to function properly and cannot be disabled.
          </p>
          <div className='flex justify-start mt-4'>
            <CommonButton
              className='!bg-primary-700 text-white dark:text-black text-xs py-1 px-[5px] rounded'
              onClick={() => {
                enableCookies(allCookies.map((cookie) => cookie.name));
              }}
              buttonText='Accept'
              size='xxs'
            ></CommonButton>

            {/* <CommonButton
              className='!bg-gray-600 dark:!bg-primary-700 text-white dark:text-black text-xs py-1 px-[5px] rounded'
              onClick={() => enableCookies(essentialCookies)}
              buttonText='Reject Non-Essential'
              size='xxs'
            ></CommonButton> */}
            <CommonButton
              className='!bg-red-600 text-white dark:text-black text-xs py-1 px-[5px] rounded mx-2'
              onClick={() => {
                enableCookies(null);
                // setShowCustomizePopup(false);
                const previousUrl = document.referrer;
                if (previousUrl && !previousUrl.includes(window.location.hostname)) {
                  window.location.href = previousUrl;
                } else {
                  window.location.href = 'https://www.google.com';
                }
              }}
              buttonText='Reject'
              size='xxs'
            ></CommonButton>
            {/* <CommonButton
              className='!bg-gray-600 dark:!bg-primary-700 text-white dark:text-black text-xs py-1 px-[5px] rounded'
              onClick={() => {
                console.log('Customize cookie settings');
                setShowCustomizePopup(true);
              }}
              buttonText='Customize'
              size='xxs'
            ></CommonButton> */}
          </div>
        </div>
      )}
      {/* {!showConcentPopup && (
        <div className='fixed bottom-[65px] right-[20px]'>
          <CommonButton
            className='!bg-gray-600 dark:!bg-primary-700 text-white dark:text-black text-xs py-1 px-[5px] rounded'
            onClick={() => {
              console.log('Customize cookie settings');
              setShowCustomizePopup(true);
            }}
            buttonText='Customize Cookie Settings'
            size='xxs'
          ></CommonButton>
        </div>
      )} */}
      {/* <CommonModal
        showModal={showCustomizePopup}
        setShowModal={setShowCustomizePopup}
        showClose={true}
        closeClassName='dark:!text-primary-700 text-xl !rounded-[50%] !border-2 !border-gray-700 dark:!border-primary-700 !right-[20px] !top-[20px] !p-4'
      >
        <div className='p-4'>
          <h3 className='text-md dark:text-white font-bold mb-3'>Manage Your Cookies</h3>
          <p className='text-sm text-gray-600 dark:text-white mb-3'>
            We use some necessary cookies to remember your preferences and enhance your experience.
            These are essential for website to function properly and cannot be disabled.
          </p>
          {allCookies.map((cookie, index) => (
            <div key={index} className='flex items-start mb-3'>
              <input
                type='checkbox'
                id={cookie.name}
                checked={
                  selectedCookies.includes(cookie.name) || essentialCookies.includes(cookie.name)
                }
                onChange={() => toggleCookieSelection(cookie.name)}
                className='mr-2 mt-[2px]'
                disabled={essentialCookies.includes(cookie.name)}
              />
              <label htmlFor={cookie.name} className='text-sm dark:text-headingColor text-gray-600'>
                <strong>{cookie.name}</strong>:{' '}
                <span className='text-gray-600 dark:text-headingColor'>{cookie.description}</span>
              </label>
            </div>
          ))}
          <div className='flex justify-start'>
            <CommonButton
              className='!bg-gray-600 dark:!bg-primary-700 text-white dark:text-black text-xs py-1 px-[5px] rounded'
              onClick={() => {
                console.log('Cookies accepted');
                enableCookies(selectedCookies);
                setShowCustomizePopup(false);
                // Logic to accept cookies can be added here
              }}
              buttonText='Save Preferences'
              size='xs'
            ></CommonButton>
            <CommonButton
              className='!bg-red-600 text-white dark:text-black text-xs py-1 px-[5px] rounded mx-2'
              onClick={() => {
                console.log('Cookies rejected');
                enableCookies(null);
                setShowCustomizePopup(false);
                if (window.history.length > 1) {
                  window.history.back(); // Navigate to the previous page
                } else {
                  window.location.href = 'https://www.google.com';
                }
              }}
              buttonText='Reject All'
              size='xs'
            ></CommonButton>
          </div>
        </div>
      </CommonModal> */}
    </div>
  );
};

export default CookieModal;
