import React, { useEffect, useRef, useState } from 'react';
import { Sidebar, Spinner, useTheme } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as GoogleIcon } from '../../assets/google.svg';
import { ReactComponent as GithubIcon } from '../../assets/github.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/linkedin.svg';
import { ReactComponent as DiscordIcon } from '../../assets/discord.svg';
import { FaUser, FaSignOutAlt, FaSignInAlt } from 'react-icons/fa';
import { SidebarProfileDropdown } from './SidebarProfileDropdown';
import { SidebarprofileHeader } from './Sidebarprofileheader';
import { RenderSidebarList } from './RenderSidebarList';
import { AppDispatch, Profile, RootState, sideNavItem } from '../../@types';
import { ReactComponent as LunorLogo } from '../../assets/Lunor.svg';
import { ReactComponent as LunorFullLogo } from '../../assets/LunorQuest.svg';
import { ReactComponent as LunorFullLogoWhite } from '../../assets/LunorQuestWhite.svg';
// import { isDesktop, isMobile } from 'react-device-detect';
import {
  guestNavBarOrgLists,
  GUEST_PROFILE_CREATE_PATH,
  orgSidebarOptions,
  userSidebarOptions,
  userNavBarOrgLists,
  USER_PROFILE_DETAILS_PATH,
  ORG_PROFILE_DETAILS_PATH,
  SIGNUP_PATH,
  WALLET_CONNECT_WARNING_TEXT,
  WALLET_BUTTON_WARNING_TEXT,
  GUEST_CHALLENGE_LIST_PATH,
} from '../../Utils/Constants';
import CommonButton from '../../Components/CommonButton';

import {
  createUserInDB,
  // fetchPasskeysFromDB,
  fetchUserFromDB,
  fetchUserProfileData,
  fetchuserProfileId,
  getSocialUserWithJWTtoken,
  updateOnChainUserProfile,
  // isPasskeyCreated,
} from '../../Utils/Helpers';
import { useAccount } from 'wagmi';
import { useQuery } from '@apollo/client';
import { ALL_ORGANISATIONS } from '../../graphql/orgs';
import ConnectWallet from '../../Components/ConnectWallet';
// import { ReactComponent as DarkLogo } from '../../assets/desights-logo-black.svg';
// import { ReactComponent as DLogo } from '../../assets/desights-D-black.svg';
// import { ReactComponent as LightLogo } from '../../assets/desights-new-logo-white.svg';
import {
  getClient,
  signInWithDiscord,
  signInWithGithub,
  signInWithGoogle,
  signInWithLinkedIn,
} from '../../Utils/Config/supabase.config';
// import { createPasskey, loadPasskeysFromLocalStorage } from '../../Utils/passkeys';
// import { getAddressFromPasskey } from '../../Utils/Config/mintNFT';
// import { updateProfileDetails } from '../../Store/Slices/ProfileSlice';
import { updateGuestState, updateInitialLoading } from '../../Store/Slices/UtilsSlice';
import {
  toggleSignUpModalAction,
  toggleSignUpModalForInfoAction,
} from '../../Store/Slices/MiscSlice';
import CommonModal from '../../Components/CommonModal';
import { useDisconnect } from 'wagmi';
import { resetRootState } from '../../Store';
import { HiOutlineExclamationCircle, HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { useSignInTracking } from '../../Utils/Hooks/useSignInTracking';
import CommonWarningModal from '../../Components/Modals/CommonWarningModal';
import {
  updateGuestStateAction,
  updateProfileDetailsAction,
  switchToUserProfileAction,
} from '../../Store/Actions/ProfileActions';
import { decodeJwtPayload, getCookie, setCookie } from '../../Utils/Helpers/CommonHelpers';
// import smallLogoWhite from '../../assets/desight-small-logo-white.png';
const SideBar = ({ className }) => {
  const isOrgSelected = useSelector((state: RootState) => state.utils.isOrgSelected);
  const currentProfile = useSelector((state: RootState) => state.profile.currentProfile);
  const defaultProfile = useSelector((state: RootState) => state.profile.defaultProfile);
  const showSignUpModal = useSelector((state: RootState) => state.misc.showSignUpModal);
  const showSignUpModalForInfo = useSelector(
    (state: RootState) => state.misc.showSignUpModalForInfo,
  );
  const [loginError, setLoginError] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  // const [infoSubmitted, setInfoSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [provider, setProvider] = useState('');
  const dispatch: AppDispatch = useDispatch();
  const { mode } = useTheme();

  const [organizationList, setAllOrganizationList] = useState([]);
  const { data: allOrg } = useQuery(ALL_ORGANISATIONS, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  });
  useEffect(() => {
    console.log('showSignUpModal', showSignUpModal);
  }, [showSignUpModal]);
  const { disconnect } = useDisconnect();
  useEffect(() => {
    if (allOrg?.fetchAllOrganisationsPage?.length > 0) {
      setAllOrganizationList(
        allOrg.fetchAllOrganisationsPage?.map((org) => {
          return {
            ...org,
            ...org?.profile,
            isOrganization: true,
            profileId: org?.id,
          };
        }),
      );
    }
  }, [allOrg]);

  const isGuest = useSelector((state: RootState) => state.utils.isGuest);
  const [currentUserProfiles, setCurrentUserProfiles] = useState<Profile[]>([]);
  const [enableProfileDropdown, setEnableProfileDropdown] = React.useState(false);
  const [renderSidebar, setRenderSidebar] = React.useState<sideNavItem[]>([]);

  const [showListselected, setShowListSelected] = React.useState<{
    login: boolean;
    key: number;
    imgSrc: string;
  }>({
    login: true,
    key: 0,
    imgSrc: 'https://m.media-amazon.com/images/I/51VOjZGpinL._UX569_.jpg',
  });
  const navigate = useNavigate();
  const { isConnected, address: userAddress, status } = useAccount();
  const [showCreateButton, setShowCreateButton] = useState(false);
  useEffect(() => {
    //
    const user = localStorage.getItem('selecteduser');
    if (!isGuest && user) {
      setShowListSelected({
        ...showListselected,
        key: parseInt(user),
        login: !parseInt(user),
      });
    }
  }, [isGuest]);

  useEffect(() => {
    if (isGuest) setRenderSidebar(guestNavBarOrgLists);
    else if (isOrgSelected)
      setRenderSidebar([
        {
          path: [ORG_PROFILE_DETAILS_PATH, currentProfile.profileId].join('/'),
          name: 'Profile',
          icon: FaUser,
        },
        ...orgSidebarOptions,
      ]);
    else
      setRenderSidebar([
        {
          path: [USER_PROFILE_DETAILS_PATH, defaultProfile.profileId].join('/'),
          name: 'My Profile',
          icon: FaUser,
        },
        ...userSidebarOptions,
      ]);
  }, [isOrgSelected, isGuest, defaultProfile.profileId, currentProfile.profileId]);

  useEffect(() => {
    if (defaultProfile.organizations.length) {
      setCurrentUserProfiles(
        organizationList.filter((org: Profile) => defaultProfile?.organizations?.includes(org.id)),
      );
    } else {
      setCurrentUserProfiles([]);
    }
  }, [defaultProfile, organizationList, currentProfile]);

  const showCreateButtonHandler = async () => {
    // TO SHOW CREATE PROFILE BUTTON IN SIDE BAR IF WALLET IS CONNECTED AND NO USERID IS FOUND
    const userProfileId = await fetchuserProfileId(userAddress);
    userProfileId ? setShowCreateButton(false) : setShowCreateButton(true);
  };

  useEffect(() => {
    isConnected && showCreateButtonHandler();
  }, [isConnected, userAddress]);

  // sidebar toggle
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(window.innerWidth >= 1024);
  const [isCurrentlyMobile, setIsCurrentlyMobile] = useState(window.innerWidth < 1024);

  // console.log('isCurrentlyMobile', isCurrentlyMobile);
  // console.log('isCollapsed', isCollapsed);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    if (!sidebarOpen && isCurrentlyMobile) {
      setIsCollapsed(false);
    }
  };
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        // Check if clicked element is either of the toggle buttons
        const toggleButton = document.querySelector('[data-drawer-toggle="default-sidebar"]');
        const desktopToggleButton = document.querySelector('[data-desktop-toggle="sidebar"]');

        if (
          (toggleButton && toggleButton.contains(event.target)) ||
          (desktopToggleButton && desktopToggleButton.contains(event.target))
        ) {
          return; // Don't close if clicking the toggle buttons
        }

        // Handle mobile view
        if (window.innerWidth < 1024 && sidebarOpen) {
          setSidebarOpen(false);
        }

        // Handle desktop view
        if (window.innerWidth >= 1024 && !isCollapsed) {
          setIsCollapsed(true);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarOpen, isCollapsed]);

  useEffect(() => {
    const supabase = getClient;
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (
        event === 'SIGNED_IN' &&
        localStorage.getItem('persistedProvider') != null &&
        localStorage.getItem('session') == null
      ) {
        localStorage.setItem('session', session.access_token);
        setProvider(localStorage.getItem('persistedProvider'));
        setLoading(true);
        dispatch(toggleSignUpModalAction(true));

        setOrGetUserData(session.user);
      }
    });

    async function setOrGetUserData(user) {
      try {
        const userData = await fetchUserFromDB(user.email);
        if (userData) {
          const userProfileData = await fetchUserProfileData(userData.id);
          console.log('userProfileData from DB', userProfileData);
          dispatch(
            updateProfileDetailsAction({ ...userProfileData, id: userProfileData?.profileId }),
          );
          setCookie('socialLogin', userData?.jwt_token);
          await trackSignIn({
            provider: localStorage.getItem('persistedProvider').toLowerCase(),
            status: 'success',
            email: userData.data.email,
            userId: userData.id,
          });
        } else {
          const undeployedNewUser = await createUserInDB(user);
          const userProfileData = await fetchUserProfileData(undeployedNewUser.id);
          dispatch(updateProfileDetailsAction({ ...userProfileData, id: undeployedNewUser.id }));
          setCookie('socialLogin', undeployedNewUser?.jwt_token);
          // dispatch(
          //   updateProfileDetails({
          //     id: undeployedNewUser.id,
          //     name: user?.user_metadata?.name,
          //     email: user?.email,
          //     profileId: undeployedNewUser.id,
          //     profileImage: user?.user_metadata?.avatar_url,
          //   }),
          // );
        }
        localStorage.removeItem('session');
        localStorage.removeItem('persistedProvider');
        dispatch(updateGuestState(false));
        setLoading(false);
        dispatch(toggleSignUpModalAction(false));
      } catch (error) {
        console.error('Error setting user data:', error);
        await trackSignIn({
          provider: localStorage.getItem('persistedProvider')?.toLowerCase() || 'unknown',
          status: 'failed',
          email: user?.email || '',
          userId: '',
        });
        setLoading(false);
        dispatch(toggleSignUpModalAction(false));
      }
    }

    if (localStorage.getItem('persistedProvider')) {
      const queryParams = new URLSearchParams(window.location.search);
      const hasError = queryParams.has('error');
      if (hasError) {
        setLoginError(true);
        dispatch(toggleSignUpModalAction(true));
      }
    }

    return () => {
      subscription.unsubscribe();
    };
  }, [dispatch]);

  const handleSignUpModal = () => {
    localStorage.removeItem('persistedProvider');
    dispatch(toggleSignUpModalAction(true));
  };
  const { trackSignIn } = useSignInTracking();
  const handleSignIn = async (providerName: string) => {
    localStorage.removeItem('session');
    localStorage.setItem('persistedProvider', providerName);
    setProvider(providerName);
    setLoading(true);

    try {
      switch (providerName) {
        case 'Google':
          await signInWithGoogle();
          break;
        case 'Github':
          await signInWithGithub();
          break;
        case 'LinkedIn':
          await signInWithLinkedIn();
          break;
        case 'Discord':
          await signInWithDiscord();
          break;
        default:
          throw new Error('Unknown provider');
      }
      // Success tracking is handled in setOrGetUserData
    } catch (error) {
      console.error(`${providerName} sign-in failed:`, error);
      await trackSignIn({
        provider: providerName.toLowerCase(),
        status: 'failed',
        email: '',
        userId: '',
      });
      setLoading(false);
    }
  };
  const handleWalletConnect = async () => {
    console.log('Sidebar: Starting wallet connect tracking');
    try {
      await trackSignIn({
        provider: 'wallet',
        status: 'success', // Initial tracking as success
        email: '',
        userId: '',
      });
      console.log('Sidebar: Successfully tracked wallet connect');
    } catch (error) {
      console.error('Sidebar: Error tracking wallet connect:', error);
      await trackSignIn({
        provider: 'wallet',
        status: 'failed',
        email: '',
        userId: '',
      });
    }
    console.log('Sidebar: Starting wallet connect');
  };
  const handleSignOut = async () => {
    try {
      // Perform sign-out actions without tracking
      disconnect();
      const supabase = getClient;
      await supabase.auth.signOut();
      dispatch(resetRootState());
      dispatch(updateGuestState(true));

      // Clear storage
      // localStorage.removeItem('persistedstate');
      setLoading(false);
      // setInfoSubmitted(false);
      localStorage.removeItem('session');
      setCookie('socialLogin', null);
      setLoading(false);
      navigate(GUEST_CHALLENGE_LIST_PATH);
    } catch (error) {
      console.error('Error during sign out:', error);
      setLoading(false);
    }
  };

  // Initialize state based on screen width only
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (
        !localStorage.getItem('currAddress') && //Condition for auto logout on all tabs
        document.visibilityState === 'visible' &&
        !getCookie('socialLogin')
      ) {
        //When wallet user has logged out in another tab
        localStorage.setItem('hasDisconnected', 'true');
        dispatch(resetRootState());
      }
      // if (document.visibilityState === 'visible' && getCookie('socialLogin')) {
      //   const userData = await getSocialUserWithJWTtoken(getCookie('socialLogin'));
      //   if (!userData) {
      //     setCookie('socialLogin', null);
      //     dispatch(resetRootState());
      //     dispatch(updateGuestStateAction(true));
      //   } else {
      //     triggerLogin();
      //   }
      // }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  async function triggerLogin(forSocialUser: boolean = false) {
    dispatch(updateInitialLoading(true));
    const userData = await getSocialUserWithJWTtoken(getCookie('socialLogin'));
    if (!userData) {
      setCookie('socialLogin', null);
      disconnect();
      dispatch(resetRootState());
      dispatch(updateGuestStateAction(true));
      dispatch(updateInitialLoading(false));
      return;
    }
    if (forSocialUser) {
      if (userData.owner) return;
    }
    const userProfileData = await fetchUserProfileData(userData.id);
    console.log('userProfileData', userProfileData);
    if (userProfileData) {
      dispatch(updateProfileDetailsAction({ ...userProfileData, id: userProfileData?.id }));
      dispatch(updateInitialLoading(false));
      dispatch(updateGuestStateAction(false));
    } else {
      setCookie('socialLogin', null);
      disconnect();
      dispatch(resetRootState());
      dispatch(updateGuestStateAction(true));
    }
    dispatch(updateInitialLoading(false));
  }
  // Handle screen resize
  useEffect(() => {
    console.log('isConnected', isConnected);
    if (
      isConnected &&
      localStorage.getItem('currAddress') === userAddress && // Condition for auto login on all tabs (wallet)
      defaultProfile.id !== decodeJwtPayload(getCookie('socialLogin'))?.id &&
      getCookie('socialLogin')
    ) {
      triggerLogin();
    } else if (
      !isConnected &&
      getCookie('socialLogin') &&
      defaultProfile.id !== decodeJwtPayload(getCookie('socialLogin'))?.id
    ) {
      // Condition for auto login on all tabs (social)/
      triggerLogin(true); //triggering login only for social users
    } else if (!localStorage.getItem('currAddress')) {
      disconnect();
      dispatch(updateGuestStateAction(true));
    }
    const handleResize = () => {
      const isMobileView = window.innerWidth < 1024;
      setIsCurrentlyMobile(isMobileView);
      if (isMobileView) {
        setIsCollapsed(false);
      } else {
        setIsCollapsed(true);
      }
    };

    window.addEventListener('resize', handleResize);

    let hideTimeout;
    const sidebar = document.getElementById('sidebar').children[0];
    // Show the scrollbar on interaction
    const showScrollbar = () => {
      sidebar.classList.remove('inactive');
      sidebar.classList.add('active');
      clearTimeout(hideTimeout); // Reset the timeout
      hideTimeout = setTimeout(() => {
        sidebar.classList.remove('active');
        sidebar.classList.add('inactive'); // Hide scrollbar after 1s
      }, 1000);
    };

    // Listen for scroll and mousemove events
    sidebar.addEventListener('scroll', showScrollbar);
    sidebar.addEventListener('mousemove', showScrollbar);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    console.log('status from ConnectWallet', status);
    if (status === 'disconnected' && !getCookie('socialLogin')) {
      setCookie('socialLogin', null);
      localStorage.setItem('hasDisconnected', 'true');
      dispatch(resetRootState());
    } else if (status === 'disconnected' && getCookie('socialLogin')) {
      checkIfWalletOwner();
    }
    async function checkIfWalletOwner() {
      const userData = await getSocialUserWithJWTtoken(getCookie('socialLogin'));
      console.log('userData from checkIfWalletOwner', userData);
      if (userData) {
        const isWalletOwner = userData.owner;
        if (isWalletOwner) {
          console.log('Wallet disconnected!');
          setCookie('socialLogin', null);
          dispatch(resetRootState());
          dispatch(updateGuestStateAction(true));
        }
      } else {
        console.log('Invalid jwt token');
        setCookie('socialLogin', null);
        dispatch(resetRootState());
        dispatch(updateGuestStateAction(true));
      }
    }
  }, [isConnected]);

  // Simplified desktop collapse handler
  const handleDesktopCollapse = () => {
    if (window.innerWidth >= 1024) {
      setIsCollapsed(!isCollapsed);
    }
  };

  const [showWarningModal, setShowWarningModal] = useState(false);

  const handleCreateAccount = () => {
    if (isCurrentlyMobile) {
      setShowWarningModal(true);
      return;
    }
    navigate(SIGNUP_PATH);
  };

  const location = useLocation();

  useEffect(() => {
    // Force user profile when on gems page
    if (location.pathname === '/gems' && isOrgSelected && defaultProfile) {
      dispatch(switchToUserProfileAction(defaultProfile));
    }
  }, [location.pathname, isOrgSelected, defaultProfile]);
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    console.log('form submitted', e.target);
    setLoading(true);
    try {
      await updateOnChainUserProfile(
        {
          ...currentProfile,
          firstname: firstName,
          lastname: lastName,
          name: firstName + ' ' + lastName,
        },
        () => {},
        navigate,
      );
      setFirstName('');
      setLastName('');
      // setInfoSubmitted(true);
      setLoading(false);
      dispatch(toggleSignUpModalForInfoAction(false));
    } catch (error) {
      console.error('Error updating user profile:', error);
      setLoading(false);
    }
  }
  return (
    <>
      <div
        className={`h-full relative transition-all duration-300 ${
          isCollapsed && !isCurrentlyMobile ? 'w-20' : 'w-auto lg:w-64'
        } ${className}`}
      >
        <button
          onClick={toggleSidebar}
          data-drawer-target='default-sidebar'
          data-drawer-toggle='default-sidebar'
          aria-controls='default-sidebar'
          type='button'
          className={`fixed top-1/2 -translate-y-1/2 z-50 inline-flex items-center justify-center p-1 text-sm text-lightTextColor rounded-full lg:hidden bg-primary-50 dark:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600 w-6 h-6 transition-all duration-300`}
          style={{
            left: sidebarOpen ? '264px' : '10px',
          }}
        >
          {sidebarOpen ? <HiChevronLeft size={16} /> : <HiChevronRight size={16} />}
        </button>

        <button
          onClick={handleDesktopCollapse}
          data-desktop-toggle='sidebar'
          className='hidden lg:flex fixed top-1/2 -translate-y-1/2 z-50 items-center justify-center w-6 h-6 rounded-full text-lightTextColor bg-primary-50 dark:bg-primary-600 cursor-pointer transition-all duration-300'
          style={{
            left: isCollapsed ? '72px' : '245px',
          }}
        >
          {isCollapsed ? <HiChevronRight size={16} /> : <HiChevronLeft size={16} />}
        </button>

        <aside
          ref={sidebarRef}
          className={`fixed top-0 left-0 z-40 h-screen transition-all duration-300 ${
            sidebarOpen ? '' : '-translate-x-full lg:translate-x-0'
          } ${isCollapsed && !isCurrentlyMobile ? 'w-20' : 'w-64'}`}
          id='default-sidebar'
          aria-label='Sidenav'
        >
          <Sidebar
            aria-label='Sidebar with multi-level dropdown example'
            className={`h-full overflow-y-auto ${isCollapsed && !isCurrentlyMobile ? '!w-20 px-0' : ''}`}
            id='sidebar'
          >
            <Sidebar.CTA className={`!p-0 ${isCollapsed && !isCurrentlyMobile ? '!w-20' : ''}`}>
              <div className='flex justify-center items-center dark:bg-primary-800'>
                <div className={`w-full ${isCollapsed && !isCurrentlyMobile ? 'px-2' : ''}`}>
                  {isGuest && location.pathname !== GUEST_PROFILE_CREATE_PATH ? (
                    <>
                      {showCreateButton && isConnected && (
                        <CommonButton
                          buttonText={isCollapsed && !isCurrentlyMobile ? '+' : 'Create My Account'}
                          buttonClassName='w-full'
                          onClick={handleCreateAccount}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <SidebarprofileHeader
                        showListselected={showListselected}
                        currentUserOrg={currentUserProfiles}
                        setEnableProfileDropdown={setEnableProfileDropdown}
                        enableProfileDropdown={enableProfileDropdown}
                        isCollapsed={isCollapsed && !isCurrentlyMobile}
                      />
                      {enableProfileDropdown && !isCollapsed && (
                        <SidebarProfileDropdown
                          ProfileUserData={[defaultProfile, ...currentUserProfiles]}
                          showListselected={showListselected}
                          setEnableProfileDropdown={setEnableProfileDropdown}
                          isCollapsed={isCollapsed && !isCurrentlyMobile}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Sidebar.CTA>

            {!isGuest && (
              <hr className='border-lightBorderColor dark:border-darkBorderColor my-2' />
            )}

            <Sidebar.Items>
              <Sidebar.ItemGroup className={isCollapsed && !isCurrentlyMobile ? '!space-y-2' : ''}>
                {!isGuest && (
                  <RenderSidebarList
                    setSidebarOpen={setSidebarOpen}
                    renderSidebar={renderSidebar}
                    isCollapsed={isCollapsed && !isCurrentlyMobile}
                  />
                )}
                {!isOrgSelected && (
                  <>
                    <hr className='border-lightBorderColor sm:mx-auto dark:border-darkBorderColor my-2' />
                    <RenderSidebarList
                      setSidebarOpen={setSidebarOpen}
                      renderSidebar={isGuest ? guestNavBarOrgLists : userNavBarOrgLists}
                      isCollapsed={isCollapsed && !isCurrentlyMobile}
                    />
                  </>
                )}
                <hr className='border-lightBorderColor sm:mx-auto dark:border-darkBorderColor my-2' />

                <div
                  className={`flex ${isCollapsed && !isCurrentlyMobile ? 'justify-center' : 'justify-between'} items-center pt-1`}
                >
                  <div
                    className={`flex flex-col ${isCollapsed && !isCurrentlyMobile ? 'items-center w-full' : 'items-center'}`}
                  >
                    {isConnected && (
                      <div
                        className={`${isConnected ? (isCollapsed && !isCurrentlyMobile ? 'mx-auto' : 'ml-4') : ''}`}
                      >
                        <ConnectWallet
                          buttonText={isCollapsed && !isCurrentlyMobile ? '+' : 'Create My Profile'}
                          responsive={true}
                          setLoginError={setLoginError}
                          // setShowSignUpModal={(val) => dispatch(toggleSignUpModalAction(val))}
                          isCollapsed={isCollapsed && !isCurrentlyMobile}
                        />
                      </div>
                    )}
                    <div
                      className={`${
                        isCollapsed && !isCurrentlyMobile
                          ? 'mx-auto justify-center items-center'
                          : 'ml-4'
                      } mt-4 mb-[80px] flex justify-center`}
                    >
                      {currentProfile.id == '' && currentProfile.profileId == '' ? (
                        <CommonButton
                          buttonText={isCollapsed && !isCurrentlyMobile ? '' : 'Sign In'}
                          buttonClassName={`${isCollapsed && !isCurrentlyMobile ? '' : ''}`}
                          ButtonIcon={FaSignInAlt}
                          onClick={handleSignUpModal}
                        />
                      ) : (
                        <CommonButton
                          buttonText={isCollapsed && !isCurrentlyMobile ? '' : 'Sign Out'}
                          buttonClassName={`${isCollapsed && !isCurrentlyMobile ? '' : ''}`}
                          ButtonIcon={FaSignOutAlt}
                          onClick={handleSignOut}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Sidebar.ItemGroup>
            </Sidebar.Items>
          </Sidebar>

          <div
            className={`${
              isCollapsed ? 'w-20' : 'w-60'
            } bg-white fixed text-center bottom-0 left-0 dark:bg-primary-800 py-2 transition-all duration-300`}
          >
            {isCollapsed ? (
              // <DLogo className='mx-auto w-9 h-9' />
              <LunorLogo className='mx-auto w-7 h-7' />
            ) : mode === 'dark' ? (
              // <DarkLogo
              //   style={{ marginLeft: 'auto', marginRight: 'auto' }}
              //   className='desights-logo'
              // />
              <LunorFullLogoWhite className='mx-auto w-[220px]' />
            ) : (
              // <LightLogo
              //   style={{ marginLeft: 'auto', marginRight: 'auto' }}
              //   className='desights-logo bg-white'
              // />
              <LunorFullLogo className='mx-auto w-[220px]' />
            )}
          </div>
        </aside>

        {sidebarOpen && (
          <div className='fixed top-0 left-0 w-screen h-screen bg-transparent z-30' />
        )}
        <CommonModal
          showModal={showSignUpModal}
          setShowModal={(val) => dispatch(toggleSignUpModalAction(val))}
          showClose={!loading && !loginError}
          bodyClassName='!rounded-[24px] !border-[2px] !border-primary-700 dark:bg-primary-900'
          closeClassName='dark:!text-primary-700 text-xl !rounded-[50%] !border-2 !border-gray-700 dark:!border-primary-700 hover:!border-primary-700 !right-[20px] !top-[20px] !p-4'
        >
          <div
            className={
              !loginError
                ? 'flex flex-col items-center justify-center px-4 py-[40px]'
                : 'flex flex-col items-center justify-center pt-[10px]'
            }
          >
            {!loading && !loginError && (
              <>
                <h2 className='text-center text-xl font-700 dark:text-white'>Hello!</h2>
                <p className='text-center text-md text-gray-500 font-400 dark:text-white my-2'>
                  Please sign in to continue
                </p>
              </>
            )}
            {!loading && !loginError && (
              <div className=''>
                {currentProfile.profileId == '' && (
                  <div className='my-2'>
                    <ConnectWallet
                      buttonText='Connect With Wallet'
                      // setShowSignUpModal={(val) => dispatch(toggleSignUpModalAction(val))}
                      setLoginError={setLoginError}
                      responsive={true}
                      onClick={handleWalletConnect}
                    />
                  </div>
                )}
                <div className='my-4 flex justify-center items-center'>
                  <hr className='w-[45%] border-lightBorderColor dark:border-darkBorderColor' />
                  <p className='text-center text-sm text-gray-500 dark:text-headingColor mx-2'>
                    OR
                  </p>
                  <hr className='w-[45%] border-lightBorderColor dark:border-darkBorderColor' />
                </div>

                <div className='my-4'>
                  <CommonButton
                    buttonClassName='w-full !bg-white !text-lightTextColor !border !border-[1px] !border-primary-700 dark:!border-none'
                    buttonText='SignIn with Google'
                    onClick={() => handleSignIn('Google')}
                    size='md'
                    ButtonIcon={GoogleIcon}
                  />
                </div>
                <div className='my-4'>
                  <CommonButton
                    buttonClassName='w-full !bg-white !text-lightTextColor !border !border-[1px] !border-primary-700 dark:!border-none'
                    buttonText='SignIn with Github'
                    onClick={() => handleSignIn('Github')}
                    size='md'
                    ButtonIcon={GithubIcon}
                  />
                </div>
                <div className='my-4'>
                  <CommonButton
                    buttonClassName='w-full !bg-white !text-lightTextColor !border !border-[1px] !border-primary-700 dark:!border-none'
                    buttonText='SignIn with LinkedIn'
                    onClick={() => handleSignIn('LinkedIn')}
                    size='md'
                    ButtonIcon={LinkedInIcon}
                  />
                </div>
                <div className='my-4'>
                  <CommonButton
                    buttonClassName='w-full !bg-white !text-lightTextColor !border !border-[1px] !border-primary-700 dark:!border-none'
                    buttonText='SignIn with Discord'
                    onClick={() => handleSignIn('Discord')}
                    size='md'
                    ButtonIcon={DiscordIcon}
                  />
                </div>
              </div>
            )}
            {loading && (
              <>
                <h3 className='text-[20px] sm:text-[24px] font-normal text-lightTextColor dark:text-darkTextColor mb-3'>
                  Signing In {provider ? `Using ${provider}` : ''}
                </h3>
                <Spinner />
              </>
            )}
            {!loading && loginError && (
              <>
                <HiOutlineExclamationCircle className='mx-auto mb-4 h-14 w-14 text-lightTextColor dark:text-darkTextColor' />
                <h3 className='text-sm sm:text-[20px] font-normal text-lightTextColor dark:text-darkTextColor mb-4'>
                  SignIn Rejected. Please SignIn Again!
                </h3>
                <CommonButton
                  buttonClassName='text-lightTextColor'
                  buttonText='Ok'
                  onClick={() => {
                    localStorage.removeItem('persistedProvider');
                    setLoginError(false);
                    dispatch(toggleSignUpModalAction(false));
                  }}
                  size='md'
                />
              </>
            )}
          </div>
        </CommonModal>
        <CommonModal
          showModal={showSignUpModalForInfo}
          setShowModal={(val) => dispatch(toggleSignUpModalForInfoAction(val))}
          showClose={true}
          className='[&>div]:!w-maxcontent'
          bodyClassName='!rounded-[24px] !border-[2px] !border-primary-700 dark:bg-primary-900'
          closeClassName='dark:!text-primary-700 text-xl !rounded-[50%] !border-2 !border-gray-700 dark:!border-primary-700 hover:!border-primary-700 !right-[20px] !top-[20px] !p-4'
        >
          <div className='bg-white dark:bg-primary-800 p-6 rounded-[16px] mx-auto'>
            <h2 className='text-2xl font-bold text-lightTextColor dark:text-darkTextColor mb-6'>
              What should we call you?
            </h2>
            <form className='space-y-4' onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor='firstName'
                  className='block text-sm font-medium text-lightTextColor dark:text-darkTextColor pb-2'
                >
                  First Name
                </label>
                <input
                  id='firstName'
                  type='text'
                  placeholder='John'
                  className='w-full px-4 py-2 rounded-lg border border-lightBorderColor dark:border-darkBorderColor bg-transparent dark:text-white focus:ring-1 focus:ring-primary-600 focus:border-primary-600'
                  required
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>

              <div>
                <label
                  htmlFor='lastName'
                  className='block text-sm font-medium text-lightTextColor dark:text-darkTextColor pb-2'
                >
                  Last Name
                </label>
                <input
                  id='lastName'
                  type='text'
                  placeholder='Wick'
                  className='w-full px-4 py-2 rounded-lg border border-lightBorderColor dark:border-darkBorderColor bg-transparent dark:text-darkTextColor focus:ring-1 focus:ring-primary-600 focus:border-primary-600'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <button
                type='submit'
                className='w-full !bg-primary-600 text-lightTextColor py-2 rounded-lg hover:!bg-primary-700 transition-colors duration-300'
              >
                Continue
              </button>
            </form>
          </div>
        </CommonModal>
      </div>

      <CommonWarningModal
        buttonText={WALLET_BUTTON_WARNING_TEXT}
        show={showWarningModal}
        warningText={WALLET_CONNECT_WARNING_TEXT}
        onButtonClick={() => {
          setShowWarningModal(false);
        }}
      />
    </>
  );
};

export default SideBar;
